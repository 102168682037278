import './assets/main.css'
import { createApp } from 'vue'
import pinia from './stores'
import App from './App.vue'
import router from './router'
import Vue3Esign from 'vue3-esign'
import 'amfe-flexible'

import 'tdesign-mobile-vue/es/style/index.css';
import '@/assets/theme.css'

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(Vue3Esign)

app.mount('#app')
