import { jsToClient } from '@/utils/client'
const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const token = ref(localStorage.getItem('token') ?? '')
    const newToken = ref(localStorage.getItem('newToken') ?? '')
    const userId = ref(localStorage.getItem('userId') ?? '')
    const workroomId = ref(localStorage.getItem('workroomId') ?? '')
    const isLogin = ref(!!token.value)
    const appId = ref(localStorage.getItem('appId') ?? '')
    const appName = ref(localStorage.getItem('appName') ?? '')
    const appVersion = ref(localStorage.getItem('appVersion') ?? '')

    // 登录
    async function login() {
      const res: any = await jsToClient('getUserInfo', '')
      const userInfo = JSON.parse(res)
      localStorage.setItem('token', userInfo.accessToken)
      localStorage.setItem('newToken', userInfo.newAccessToken)
      localStorage.setItem('userId', userInfo.userId)
      localStorage.setItem('workroomId', userInfo.workRoomId)

      token.value = userInfo.accessToken
      newToken.value = userInfo.newAccessToken
      userId.value = userInfo.userId
      workroomId.value = userInfo.workRoomId

      const system: any = await jsToClient('getSystemInfo', '')
      const _system = JSON.parse(system)
      localStorage.setItem('appId', _system.appId)
      localStorage.setItem('appName', _system.appName)
      localStorage.setItem('appVersion', _system.appVersion)
      appId.value = _system.appId
      appName.value = _system.appName
      appVersion.value = _system.appVersion

      isLogin.value = true
    }

    // 登出
    async function logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('newToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('workroomId')
      localStorage.removeItem('appId')
      localStorage.removeItem('appName')
      localStorage.removeItem('appVersion')
      token.value = ''
      newToken.value = ''
      userId.value = ''
      workroomId.value = ''
      appId.value = ''
      appName.value = ''
      appVersion.value = ''
      isLogin.value = false
    }

    return {
      token,
      newToken,
      userId,
      workroomId,
      appId,
      appName,
      appVersion,
      isLogin,
      login,
      logout,
    }
  },
)

export default useUserStore
