import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('@/views/main.vue'),
      meta: {
        name: '首页',
      },
    },
    // debug
    {
      path: '/debug',
      name: 'debug',
      component: () => import('@/views/debug.vue'),
    },
    // 在线问诊
    {
      path: '/consultation/index',
      name: 'consultationIndex',
      component: () => import('@/views/consultation/index.vue'),
    },
    // 在线问诊-搜索框结果页
    {
      path: '/consultation/searchResult',
      name: 'consultationSearchResult',
      component: () => import('@/views/consultation/searchResult.vue'),
    },
    // 在线问诊-诊断小结
    {
      path: '/consultation/result',
      name: 'consultationResult',
      component: () => import('@/views/consultation/result.vue'),
    },
    // 在线问诊-内分泌等三个科室
    {
      path: '/consultation/search',
      name: 'consultationSearch',
      component: () => import('@/views/consultation/search.vue'),
    },
    // 在线问诊-问诊信息
    {
      path: '/consultation/order',
      name: 'consultationOrder',
      component: () => import('@/views/consultation/order.vue'),
    },
    // 在线问诊-添加就诊人
    {
      path: '/consultation/add',
      name: 'consultationAdd',
      component: () => import('@/views/consultation/add.vue'),
    },
    // 在线问诊-提交成功
    {
      path: '/consultation/success',
      name: 'consultationSuccess',
      component: () => import('@/views/consultation/success.vue'),
    },
    // 商城
    {
      path: '/shop/index',
      name: 'shopIndex',
      component: () => import('@/views/shop/index.vue'),
    },
    // 商城-详情页
    {
      path: '/shop/detail',
      name: 'shopDetail',
      component: () => import('@/views/shop/detail.vue'),
    },
    // 商城-添加地址
    {
      path: '/shop/address',
      name: 'shopAddress',
      component: () => import('@/views/shop/address.vue'),
    },
    // 商城-选择问诊医生
    {
      path: '/shop/choose',
      name: 'shopChoose',
      component: () => import('@/views/shop/choose.vue'),
    },
    // 商城-支付成功
    {
      path: '/shop/success',
      name: 'shopSuccess',
      component: () => import('@/views/shop/success.vue'),
    },
    // 检查报告
    {
      path: '/inspect_report/index',
      name: 'inspectreportIndex',
      component: () => import('@/views/inspect_report/index.vue'),
    },
    // 健康号直播
    {
      path: '/live/index',
      name: 'liveIndex',
      component: () => import('@/views/live/index.vue'),
    },
    // 消息中心-小助手
    {
      path: '/message/helper',
      name: 'messageHelper',
      component: () => import('@/views/message_center/helper.vue'),
    },
    // 消息中心-预约登记
    {
      path: '/message/appoint',
      name: 'messageAppoint',
      component: () => import('@/views/message_center/appoint.vue'),
    },
    // 消息中心-医院通知
    {
      path: '/message/notice',
      name: 'messageNotice',
      component: () => import('@/views/message_center/notice.vue'),
    },
    // 消息中心-评论互动
    {
      path: '/message/comment',
      name: 'messageComment',
      component: () => import('@/views/message_center/comment.vue'),
    },
    // 消息中心-医生说
    {
      path: '/message/doctor',
      name: 'messageDoctor',
      component: () => import('@/views/message_center/doctor.vue'),
    },
    // 测量-用药提醒
    {
      path: '/measure/reminder',
      name: 'measureReminder',
      component: () => import('@/views/measure/reminder.vue'),
    },
    // 测量-血糖测量提醒
    {
      path: '/measure/xt/reminder',
      name: 'measureXTReminder',
      component: () => import('@/views/measure/xtReminder.vue'),
    },
    // 测量-数据记录
    {
      path: '/measure/record',
      name: 'measureRecord',
      component: () => import('@/views/measure/record.vue'),
    },
    // 测量-流速峰值
    {
      path: '/measure/lsfz',
      name: 'measureLsfz',
      component: () => import('@/views/measure/lsfz.vue'),
    },
    // 测量-血样饱和
    {
      path: '/measure/xybh',
      name: 'measureXybh',
      component: () => import('@/views/measure/xybh.vue'),
    },
    // 测量-BMI
    {
      path: '/measure/BMI',
      name: 'measureBMI',
      component: () => import('@/views/measure/BMI.vue'),
    },
    // 测量-运动记录
    {
      path: '/measure/motion',
      name: 'measureMotion',
      component: () => import('@/views/measure/motion.vue'),
    },
    // 测量-血脂
    {
      path: '/measure/xz',
      name: 'measureXz',
      component: () => import('@/views/measure/xz.vue'),
    },
    // 测量-血糖
    {
      path: '/measure/xt',
      name: 'measureXt',
      component: () => import('@/views/measure/xt.vue'),
    },
    // 测量-血压
    {
      path: '/measure/xy',
      name: 'measureXy',
      component: () => import('@/views/measure/xy.vue'),
    },
    // 我的-问卷评估
    {
      path: '/my/evaluation',
      name: 'myEvaluation',
      component: () => import('@/views/my/evaluation.vue'),
    },
    // 我的-我的科室
    {
      path: '/my/workroom',
      name: 'myWorkroom',
      component: () => import('@/views/my/workroom.vue'),
    },
    // 我的-我的预约
    {
      path: '/my/order',
      name: 'myOrder',
      component: () => import('@/views/my/order.vue'),
    },
    // 我的-我的预约详情
    {
      path: '/my/orderDetail',
      name: 'myOrderDetail',
      component: () => import('@/views/my/orderDetail.vue'),
    },
    // 我的-我的问诊
    {
      path: '/my/consultation',
      name: 'myConsultation',
      component: () => import('@/views/my/consultation.vue'),
    },
    // 我的-我的问诊-问诊详情
    {
      path: '/my/consultationDetail',
      name: 'myConsultationDetail',
      component: () => import('@/views/my/consultationDetail.vue'),
    },
    // 我的-就诊人管理
    {
      path: '/my/patient',
      name: 'myPatient',
      component: () => import('@/views/my/patient.vue'),
    },
    // 我的-我关注的医生
    {
      path: '/my/doctors',
      name: 'myDoctors',
      component: () => import('@/views/my/doctors.vue'),
    },
    // 我的-服务包首页
    {
      path: '/my/servicePackage/index',
      name: 'myServicePackageIndex',
      component: () => import('@/views/my/servicePackage/index.vue'),
    },
    // 我的-服务包绑定
    {
      path: '/my/servicePackage/binding',
      name: 'myServicePackageBinding',
      component: () => import('@/views/my/servicePackage/binding.vue'),
    },
    // 我的-服务包订单
    {
      path: '/my/servicePackage/order',
      name: 'myServicePackageOrder',
      component: () => import('@/views/my/servicePackage/order.vue'),
    },
    // 我的-服务包兑换失败
    {
      path: '/my/servicePackage/fail',
      name: 'myServicePackageFail',
      component: () => import('@/views/my/servicePackage/fail.vue'),
    },
    // 我的-亲友关注首页
    {
      path: '/my/relatives/index',
      name: 'myRelativesIndex',
      component: () => import('@/views/my/relatives/index.vue'),
    },
    // 我的-亲友关注识别失败
    {
      path: '/my/relatives/fail',
      name: 'myRelativesFail',
      component: () => import('@/views/my/relatives/fail.vue'),
    },
    // 我的-亲友关注识别结果
    {
      path: '/my/relatives/result',
      name: 'myRelativesResult',
      component: () => import('@/views/my/relatives/result.vue'),
    },
    // 我的-亲友关注设置
    {
      path: '/my/relatives/config',
      name: 'myRelativesConfig',
      component: () => import('@/views/my/relatives/config.vue'),
    },
    // 科室介绍
    {
      path: '/workroom/desc',
      name: 'workroomDetail',
      component: () => import('@/views/other/workroom_detail.vue'),
    },
    // 未加入科室-完善信息(通用项目)
    {
      path: '/addWorkroom/info',
      name: 'addWorkroomInfo',
      component: () => import('@/views/other/add_workroom/info.vue'),
    },
    // 未加入科室-完善信息(关爱行动 暂未支持)
    {
      path: '/addWorkroom/infoG',
      name: 'addWorkroomInfoG',
      component: () => import('@/views/other/add_workroom/infoG.vue'),
    },
    // 未加入科室-签署协议
    {
      path: '/addWorkroom/agreement',
      name: 'addWorkroomAgreement',
      component: () => import('@/views/other/add_workroom/agreement.vue'),
    },
    // 未加入科室-签名
    {
      path: '/addWorkroom/sign',
      name: 'addWorkroomSign',
      component: () => import('@/views/other/add_workroom/sign.vue'),
    },
    // 下载页
    {
      path: '/other/download',
      name: 'otherDownload',
      component: () => import('@/views/other/download.vue'),
    },
    // pdf viewer
    {
      path: '/other/pdfViewer',
      name: 'pdfViewer',
      component: () => import('@/views/other/pdfViewer.vue'),
    },
    // 服务包兑换
    {
      path: '/activity/servicePackageExchange',
      name: 'servicePackageExchange',
      component: () => import('@/views/activity/servicePackageExchange/index.vue'),
    },
    // 服务包兑换结果页
    {
      path: '/activity/servicePackageExchange/success',
      name: 'servicePackageExchangeSuccess',
      component: () => import('@/views/activity/servicePackageExchange/success.vue'),
    },
    // 医生工作室患者档案-量表列表页
    {
      path: '/shop/scale/all',
      name: 'scaleAll',
      component: () => import('@/views/shop/scale/all.vue'),
    },
    // 量表1
    {
      path: '/shop/scale/testQuestionsA',
      name: 'shopScaleTestA',
      component: () => import('@/views/shop/scale/testQuestionsA.vue'),
    },
    // 量表1结果页
    {
      path: '/shop/scale/testResultA',
      name: 'shopScaleResultA',
      component: () => import('@/views/shop/scale/testResultA.vue'),
    },
    // 量表2
    {
      path: '/shop/scale/testQuestionsB',
      name: 'shopScaleTestB',
      component: () => import('@/views/shop/scale/testQuestionsB.vue'),
    },
    // 量表2结果页
    {
      path: '/shop/scale/testResultB',
      name: 'shopScaleResultB',
      component: () => import('@/views/shop/scale/testResultB.vue'),
    },
    // 扫码结果页
    {
      path: '/other/scan/binding',
      name: 'scanBinding',
      component: () => import('@/views/other/add_workroom/binding.vue'),
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  next()
}) 

router.afterEach((to, from) => {
})

export default router
