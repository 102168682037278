import dsbridge from 'dsbridge'
// 命名空间
const namespace = dsbridge.call('getNameSpace')
// js调客户端
/**
 * ------常用方法名-----
 * */
const jsToClient = (methodName: string, params: any) => {
	const para = typeof params === 'string' ? params : JSON.stringify(params)
	return new Promise((resolve, reject) => {
		const nativeMethod = namespace + '.' + methodName
		const hasNativeMethod = dsbridge.hasNativeMethod(nativeMethod)
		if (hasNativeMethod) {
			dsbridge.call(nativeMethod, para, res => {
				resolve(res)
			})
		} else {
			reject({message: '未找到方法！'})
		}
	})
}

// 客户端调js
const clientToJs = (methodName: string, callback: any) => {
	dsbridge.register(namespace + '.' + methodName, (res: any) => {
		callback && callback(res)
	})
}

export {
	namespace,
	jsToClient,
	clientToJs
}
