<script setup lang="ts">
import { RouterView } from 'vue-router'
import useUserStore from '@/stores/user'
import VConsole from 'vconsole';
if (import.meta.env.DEV) {
const vConsole = new VConsole()
// vConsole.destroy()
}
useUserStore().login()
</script>

<template>
  <RouterView />
</template>

<style>
.c3 {
  color: #333;
}
.c4 {
  color: #444;
}
.c6 {
  color: #666;
}
.c9 {
  color: #999;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.bold {
  font-weight: 500;
}
</style>
